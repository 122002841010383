@keyframes animLand {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -335px 0;
  }
}

@keyframes animSky {
  0% {
    background-position: 0 100%;
  }

  100% {
    background-position: -1104px 100%;
  }
}

@keyframes animBird {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 -96px;
  }
}

@keyframes animPipe {
  0% {
    left: 900px;
  }

  100% {
    left: -100px;
  }
}

@keyframes animCeiling {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -63px 0;
  }
}

@font-face {
  font-family: Colfax;
  src: url("Colfax-WebLight.01d7def6.woff2") format("woff2"), url("Colfax-WebLight.09b34a59.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html {
  color: gray;
  font-family: Colfax, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.2rem;
}

.container {
  max-width: 910px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 930px) {
  .container {
    padding: 0 20px;
  }
}

header {
  height: 300px;
  background-size: cover;
  position: relative;
}

.logo {
  max-width: 120px;
  position: absolute;
  top: 60px;
  left: -10px;
}

.logo img {
  max-width: 100%;
}

@media only screen and (max-width: 1024px) {
  .logo {
    left: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .logo {
    max-width: 100px;
  }
}

.text-block {
  min-height: 300px;
  background-color: #ffed00;
  margin: 30px 0;
  padding: 25px 230px 40px 40px;
  line-height: 1.75rem;
  position: relative;
}

.text-block p {
  margin-bottom: 2rem;
}

.text-block img {
  width: 175px;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 450px) {
  .text-block {
    padding: 230px 25px 40px 40px;
  }
}

.contact {
  min-height: 300px;
  margin-bottom: 30px;
  display: flex;
}

.contact .book-image {
  flex-grow: 1;
  position: relative;
}

.contact .book-image img {
  width: 820px;
  position: absolute;
  bottom: 0;
  right: -230px;
}

.contact .contact-form {
  z-index: 20;
  width: 550px;
  background: #fff;
  padding-top: 50px;
  position: relative;
}

.contact .contact-form h2 {
  padding-left: 25px;
  font-size: 1.6rem;
}

footer {
  margin-bottom: 30px;
}

footer h3 {
  margin-bottom: 15px;
  font-size: 1.4rem;
}

footer a {
  color: gray;
  font-size: 15px;
  line-height: 150%;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer .socials {
  margin-bottom: 15px;
}

footer .socials ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer .socials li {
  width: 35px;
  height: 35px;
  border: 2px solid #87888a;
  margin-right: 10px;
  display: inline-block;
}

footer .socials li a {
  text-align: center;
  color: #1a171b;
  vertical-align: middle;
  padding: 6px 8px 8px;
  font-size: 18px;
  text-decoration: none;
  display: block;
}

footer p {
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 150%;
}

.bordered {
  border: 1px solid gray;
  padding: 20px;
}

form {
  max-width: 320px;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 20px 0 0;
  padding: 0 20px;
  display: flex;
}

form input {
  box-shadow: none;
  width: 100%;
  color: #000;
  background-color: #ffed00;
  border: 0 solid #0000;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: block;
}

form label {
  margin: 0 0 20px;
  display: block;
}

form label.parsley-error {
  border: 0;
  margin: 0 0 10px;
}

form label input[type="checkbox"] {
  width: 25px;
  display: inline-block;
}

form .donation {
  margin-top: 20px;
}

form .parsley-error {
  border: 1px solid red;
}

form p {
  margin: 10px 0;
}

form button {
  color: #000;
  background-color: #ffed00;
  border: 0 solid #0000;
  padding: 8px 10px;
  font-weight: bold;
}

.parsley-required {
  color: red;
  margin-bottom: 2px;
  font-size: .9rem;
  list-style: none;
}

/*# sourceMappingURL=index.21440f97.css.map */
