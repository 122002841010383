@import "animations";
@import "media-queries";

@font-face {
  font-family: "Colfax";
  src: url("font/Colfax-WebLight.woff2") format("woff2"),
    url("font/Colfax-WebLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html {
  color: #808080;
  font-family: "Colfax", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.2rem;
}

.container {
  max-width: 910px;
  margin: 0 auto;
  position: relative;

  @include max-screen(930px) {
    padding: 0 20px;
  }
}

header {
  position: relative;
  height: 300px;
  background-size: cover;
}

.logo {
  max-width: 120px;
  position: absolute;
  left: -10px;
  top: 60px;

  img {
    max-width: 100%;
  }

  @include max-screen(1024px) {
    left: 10px;
  }
  @include max-screen(450px) {
    max-width: 100px;
  }
}

.text-block {
  position: relative;
  margin: 30px 0;
  background-color: #ffed00;
  padding: 25px 230px 40px 40px;
  min-height: 300px;
  line-height: 1.75rem;

  p {
    margin-bottom: 2rem;
  }

  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 175px;
  }

  @include max-screen(450px) {
    padding: 230px 25px 40px 40px;
  }
}

.contact {
  display: flex;
  min-height: 300px;
  margin-bottom: 30px;

  .book-image {
    position: relative;
    flex-grow: 1;

    img {
      width: 820px;
      position: absolute;
      bottom: 0;
      right: -230px;
    }
  }

  .contact-form {
    position: relative;
    z-index: 20;
    width: 550px;
    background: #fff;
    padding-top: 50px;

    h2 {
      font-size: 1.6rem;
      padding-left: 25px;
    }
  }
}

footer {
  margin-bottom: 30px;

  h3 {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  a {
    color: #808080;
    text-decoration: none;
    line-height: 150%;
    font-size: 15px;

    &:hover {
      text-decoration: underline;
    }
  }

  .socials {
    margin-bottom: 15px;

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li {
      width: 35px;
      height: 35px;
      border: 2px solid #87888a;
      margin-right: 10px;
      display: inline-block;

      a {
        text-align: center;
        text-decoration: none;
        color: #1a171b;
        font-size: 18px;
        padding: 8px;
        display: block;
        vertical-align: middle;
        padding-top: 6px;
      }
    }
  }

  p {
    line-height: 150%;
    font-size: 15px;
    margin-bottom: 15px;
  }
}

.bordered {
  padding: 20px;
  border: 1px solid #808080;
}

form {
  margin: 20px 0 0 0;
  padding: 0 20px;
  max-width: 320px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  input {
    display: block;
    background-color: #ffed00;
    border: 0 solid transparent;
    box-shadow: none;
    padding: 5px 10px;
    width: 100%;
    color: #000;
    margin-bottom: 5px;
  }

  label {
    display: block;
    margin: 0 0 20px;

    &.parsley-error {
      border: 0;
      margin: 0 0 10px;
    }

    input[type="checkbox"] {
      display: inline-block;
      width: 25px;
    }
  }

  .donation {
    margin-top: 20px;
  }

  .parsley-error {
    border: 1px solid red;
  }

  p {
    margin: 10px 0;
  }

  button {
    padding: 8px 10px;
    background-color: #ffed00;
    color: #000;
    border: 0 solid transparent;
    font-weight: bold;
  }
}

.parsley-required {
  list-style: none;
  color: red;
  font-size: 0.9rem;
  margin-bottom: 2px;
}
